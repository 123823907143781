
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiArrowLeftThin, mdiPlus } from '@mdi/js'

import WorkforcePlanningTimeline from '@/views/planning/views/workforcePlanningTimeline/components/WorkforcePlanningTimeline.vue'
import PositionDetailsTable from '@/views/planning/views/positionDetails/components/PositionDetailsTable.vue'

import { handleError } from '@/utils/handleError'
import { convertToEuro } from '@/utils/convertCurrency'
import { dateDashNotationToDot } from '@/utils/convertDate'
import { convertDotIntoCommaInput } from '@/utils/convertInput'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetPosition } from '@/api/position'

import { AxiosError } from 'axios'
import { Rights } from '@/api/types/right'
import { POSITIONSTATUS } from '../workforcePlanning/types'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'PositionDetails',
  components: {
    PositionDetailsTable,
    WorkforcePlanningTimeline,
    AddEditAssignmentDialog: () =>
      import('@/views/contractData/views/assignments/components/AddEditAssignmentDialog.vue'),
  },
  setup: (_, { root }) => {
    const { getPosition, data: position, isLoading: isLoadingPosition } = useGetPosition()

    async function init() {
      try {
        await getPosition(Number(root.$route.params.positionId))
      } catch (error: unknown) {
        const axiosError = error as AxiosError

        if (axiosError.response?.status === 404) {
          // in case position is deleted and init is impossible
          root.$router.push({
            name: 'planning-workforce-planning',
          })
        } else {
          handleError(error)
        }
      }
    }

    init()

    function onClickBack(): void {
      root.$router.back()
    }

    const isAddEditAssignmentDialogOpen = ref(false)

    function onCloseAddAssignmentDialog(): void {
      isAddEditAssignmentDialogOpen.value = false
    }

    function displayReasonForDisabledButton(): TranslateResult | null {
      if (!position.value?.approvals?.length) {
        return root.$t('planning.workforcePlanning.positionDetails.actions.addAssignment.disabled.noApproval')
      } else if (position.value?.positionStatus !== POSITIONSTATUS.ACTIVE) {
        return root.$t('planning.workforcePlanning.positionDetails.actions.addAssignment.disabled.notActive')
      } else if (position.value?.availableScopeForAssignment <= 0) {
        return root.$t('planning.workforcePlanning.positionDetails.actions.addAssignment.disabled.noScope')
      } else {
        return null
      }
    }

    return reactive({
      icons: {
        mdiPlus,
        mdiArrowLeftThin,
      },
      constants: {
        POSITIONSTATUS,
        Rights,
      },

      state: {
        position,
        isLoadingPosition,

        isAddEditAssignmentDialogOpen,
      },

      functions: {
        init,

        convertToEuro,
        hasSufficientRights,
        dateDashNotationToDot,
        convertDotIntoCommaInput,

        displayReasonForDisabledButton,
      },
      listeners: {
        onClickBack,
        onCloseAddAssignmentDialog,
      },
    })
  },
})
