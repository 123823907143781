import { Ref, ref } from '@vue/composition-api'
import { sortBy, groupBy } from 'lodash-es'

import { useGetCapacityProfUnitExtensions } from '@/api/capacityProfUnitExtension'

import { CapacityProductIncrementEntryOutput } from '@/api/types/capacityProductIncrementEntry'
import {
  CapacityProfUnitExtensionOutput,
  UseGetCapacityProfUnitExtensions,
} from '@/api/types/capacityProfUnitExtension'
import { PaginationResponse } from '@/composables/types/usePagination'

interface CapacityCol {
  sortable: boolean
  text: string
  value: string
  align: string
  meta: {
    year: number
    yearProductIncrement: number
  }
}

interface UseCapacity {
  CAPACITY_RELEVANT_PROPERTIES: string[]
  capacityCols: Ref<CapacityCol[]>
  getCapacities: UseGetCapacityProfUnitExtensions['exec']
  capacities: Ref<CapacityProfUnitExtensionOutput[]>
  isLoadingCapacities: Ref<boolean>
  paginationResponse: Ref<PaginationResponse>
  generateCapacityCols: () => void
}

const capacityCols = ref<CapacityCol[]>([])

function useCapacity(): UseCapacity {
  const CAPACITY_RELEVANT_PROPERTIES = [
    'calculatedAssumedCapacity',
    'calculatedPhysicalCapacity',
    'plannedAssumedCapacity',
    'plannedPhysicalCapacity',
  ]

  const {
    exec: getCapacities,
    data: capacities,
    isLoading: isLoadingCapacities,
    paginationResponse,
  } = useGetCapacityProfUnitExtensions()

  function generateCapacityCols(): void {
    capacityCols.value = []

    const piEntries: CapacityProductIncrementEntryOutput[] = capacities.value
      .map((capacity) => capacity.capacityProductIncrementEntries)
      .flat()

    const sortedCapacityPIs = groupBy(
      sortBy(piEntries, ['totalProductIncrement', 'yearProductIncrement']),
      'totalProductIncrement'
    )

    const totalProductIncrementValues = Object.keys(sortedCapacityPIs)

    totalProductIncrementValues.forEach((totalPi) => {
      capacityCols.value.push({
        sortable: false,
        text: `${sortedCapacityPIs[totalPi][0].yearProductIncrement} (${totalPi})`,
        value: `${totalPi}`,
        align: 'center',
        meta: {
          year: sortedCapacityPIs[totalPi][0].year,
          yearProductIncrement: sortedCapacityPIs[totalPi][0].yearProductIncrement,
        },
      })
    })
  }

  return {
    CAPACITY_RELEVANT_PROPERTIES,
    capacityCols,
    getCapacities,
    capacities,
    isLoadingCapacities,
    paginationResponse,
    generateCapacityCols,
  }
}

export { useCapacity }
