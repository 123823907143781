import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'
import {
  EnumItemId,
  EnumItemInput,
  EnumItemOutput,
  UseCreateEnumItem,
  UseDeleteEnumItem,
  UseGetEnumItems,
  UseGetEnumItemsBasic,
} from './types/enumItem'
import { BasicEntity } from './types/misc'

const VERSION = 'v1'
const RESOURCE = 'enum-items'

function useGetEnumItems(): UseGetEnumItems {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetEnumItems['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })
  return axios
}

function useGetEnumItemsBasic(): UseGetEnumItemsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateEnumItem(): UseCreateEnumItem {
  const axios = useAxios<PromiseType<ReturnType<UseCreateEnumItem['createEnumItem']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createEnumItem(data: EnumItemInput): Promise<EnumItemOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createEnumItem,
  }
}

function useDeleteEnumItem(): UseDeleteEnumItem {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteEnumItem['deleteEnumItem']>>>({
    method: 'DELETE',
  })

  function deleteEnumItem(id: EnumItemId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteEnumItem,
  }
}

export { useGetEnumItems, useGetEnumItemsBasic, useCreateEnumItem, useDeleteEnumItem }
