enum FILTER_FIELD_KEY {
  TenderingIds = 'ids',
  DeadlineEndFrom = 'deadlineEndFrom',
  DeadlineEndTo = 'deadlineEndTo',
  OrganizationalUnitIds = 'organizationalUnitIds',
  StatusIds = 'statusIds',
  ProfileIds = 'profileIds',
  PositionIds = 'positionIds',
  Sourcing = 'sourcing',
}

enum TENDERING_STATUS {
  ACTIVE = 'aktiv',
  CLOSED = 'Ausschreibung geschlossen',
  APPLICATION_RECEIVED = 'Bewerbung eingegangen',
  RENEW = 'neu ausschreiben',
}

export { FILTER_FIELD_KEY, TENDERING_STATUS }
