var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-5",
    attrs: {
      "fluid": _vm.$vuetify.breakpoint.lgAndDown && true
    }
  }, [_c('v-row', {
    staticClass: "mx-0 mb-3"
  }, [_c('v-btn', {
    staticClass: "rounded-lg white",
    attrs: {
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onClickBack();
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiArrowLeftThin) + " ")]), _vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionDetails.action.back')) + " ")], 1), _vm.state.isLoadingPosition ? _c('p', {
    staticClass: "mt-2 ml-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionDetails.loadingPosition')) + " ")]) : _vm._e(), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_vm.functions.hasSufficientRights(_vm.constants.Rights.ASSIGNMENT_CREATE) && _vm.functions.hasSufficientRights(_vm.constants.Rights.CONTRACT_READ_ALL) && _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "ml-auto rounded-lg white",
          attrs: {
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddEditAssignmentDialogOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionDetails.actions.addAssignment.title')) + " ")], 1) : _vm._e()], 1)];
      }
    }])
  }, [_vm._v(" " + _vm._s(_vm.functions.displayReasonForDisabledButton()) + " ")])], 1), !_vm.state.isLoadingPosition ? _c('PositionDetailsTable', {
    attrs: {
      "position": _vm.state.position
    },
    on: {
      "invoke:init": function invokeInit($event) {
        return _vm.functions.init();
      }
    }
  }) : _vm._e(), !_vm.state.isLoadingPosition ? _c('WorkforcePlanningTimeline', {
    attrs: {
      "position": _vm.state.position
    }
  }) : _vm._e(), _vm.state.isAddEditAssignmentDialogOpen ? _c('AddEditAssignmentDialog', {
    attrs: {
      "position": _vm.state.position
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onCloseAddAssignmentDialog();
      },
      "added-edited": function addedEdited($event) {
        return _vm.functions.init();
      }
    },
    model: {
      value: _vm.state.isAddEditAssignmentDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditAssignmentDialogOpen", $$v);
      },
      expression: "state.isAddEditAssignmentDialogOpen"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }