var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "my-3 rounded-lg",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', [_vm.state.hasDataLoaded || _vm.state.isPositionDetailView ? _c('div', {
    staticClass: "relative",
    attrs: {
      "id": "chart"
    }
  }, [_c('Apexchart', {
    attrs: {
      "type": "rangeBar",
      "height": Math.max(_vm.state.isPositionDetailView ? 310 : 280, _vm.state.positionGroups.length * _vm.state.series.length * 30),
      "options": _vm.state.chartOptions,
      "series": _vm.state.series
    }
  })], 1) : _c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionTimeline.noPositions')) + " ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }