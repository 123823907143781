import { Ref, ref } from '@vue/composition-api'
import { AxiosRequestConfig } from 'axios'

import { axiosInstance } from '@/plugins/axios'

import { useAxios } from '@/composables/useAxios'

import { Page, PaginationResponse } from '@/composables/types/usePagination'
import { UseAxiosPaginated } from '@/composables/types/useAxiosPaginated'

const useAxiosPaginated = <T = unknown>(
  baseCfg: AxiosRequestConfig = {},
  instance = axiosInstance
): UseAxiosPaginated<T> => {
  const paginationResponse = ref<PaginationResponse>({
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    pageable: {
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: false,
      sort: {
        sorted: false,
        unsortet: false,
        empty: false,
      },
      unpaged: false,
    },
    size: 0,
    sort: {
      sorted: false,
      unsortet: false,
      empty: false,
    },
    totalElements: 0,
    totalPages: 0,
  })

  const data: Ref<T[]> = ref([])

  const axios = useAxios<Page<T>>(baseCfg, instance)

  const exec = async (cfg: AxiosRequestConfig = {}) =>
    axios.exec(cfg).then((response) => {
      if (response?.content) {
        const { content, ...paginationParams } = response
        data.value = content
        paginationResponse.value = paginationParams
        return content
      }
      return []
    })

  return {
    ...axios,
    data,
    exec,
    paginationResponse,
  }
}

export { useAxiosPaginated }
