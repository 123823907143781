import { i18n } from '@/plugins/i18n'
import { camelCase as toCamelCase } from 'lodash-es'

enum SOURCINGTYPE {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

enum POSITIONSTATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  FREEZE = 'FREEZE',
}

enum FILTER_FIELD_KEY {
  PersonName = 'name',
  StartFrom = 'startFrom',
  StartTo = 'startTo',
  EndFrom = 'endFrom',
  EndTo = 'endTo',
  ScopeFrom = 'scopeFrom',
  ScopeTo = 'scopeTo',
  HourlyRateFrom = 'hourlyRateFrom',
  HourlyRateTo = 'hourlyRateTo',
  SourcingTypes = 'sourcingTypes',
  PositionStatuses = 'positionStatuses',
  ProfileIds = 'profileIds',
  ProfessionalUnitIds = 'professionalUnitIds',
  ProfessionalUnitIdsWithChildren = 'professionalUnitIdsWithChildren',
  OrganizationalUnitIds = 'organizationalUnitIds',
  OrganizationalUnitIdsWithChildren = 'organizationalUnitIdsWithChildren',
  BudgetSourceOrgUnitIds = 'budgetSourceOrgUnitIds',
  ApprovedByUserIds = 'approvedByUserIds',
  Limited = 'limited',
  NotApproved = 'notApproved',
  Ids = 'ids',
  NotAssigned = 'notAssigned',
  NotAssignedByDate = 'notAssignedByDate',
  ActiveTendering = 'activeTendering',
  ContractualRelationships = 'contractualRelationships',
  ScenarioId = 'scenarioId',
  Acquisition = 'acquisition',
}

const POSITION_STATUS = Object.keys(POSITIONSTATUS).map((statusKey) => {
  const statusValue = POSITIONSTATUS[statusKey]
  return {
    id: statusValue,
    name: i18n.t(`planning.workforcePlanning.form.position.status.${toCamelCase(statusValue)}`),
  }
})

const SOURCING_TYPE = Object.keys(SOURCINGTYPE).map((sourcingKey) => {
  const sourcingKeyValue = SOURCINGTYPE[sourcingKey]
  return {
    id: sourcingKeyValue,
    name: i18n.t(`planning.workforcePlanning.form.sourcing.type.${toCamelCase(sourcingKeyValue)}`),
  }
})

const ACTIVE_TENDERING = [
  {
    value: true,
    name: i18n.t(`planning.workforcePlanning.form.activeTendering`),
  },
  {
    value: false,
    name: i18n.t(`planning.workforcePlanning.form.noActiveTendering`),
  },
]

export { SOURCINGTYPE, POSITIONSTATUS, FILTER_FIELD_KEY, SOURCING_TYPE, POSITION_STATUS, ACTIVE_TENDERING }
