import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  AssignmentOutput,
  AssignmentId,
  AssignmentInput,
  UseGetAssignments,
  UseGetAssignmentsBasic,
  UseCreateAssignment,
  UseUpdateAssignment,
  UseDeleteAssignment,
  UseExportAssignments,
  UseGetAssignment,
} from './types/assignment'
import { OptionalFiltersParam } from '@/composables/types/useFilter'
import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'

const VERSION = 'v1'
const RESOURCE = 'assignments'

function useGetAssignments(): UseGetAssignments {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetAssignments['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetAssignment(): UseGetAssignment {
  const axios = useAxios<PromiseType<ReturnType<UseGetAssignment['getAssignment']>>>({ method: 'GET' })

  function getAssignment(id: AssignmentId): Promise<AssignmentOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getAssignment,
  }
}

function useGetAssignmentsBasic(): UseGetAssignmentsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateAssignment(): UseCreateAssignment {
  const axios = useAxios<PromiseType<ReturnType<UseCreateAssignment['createAssignment']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createAssignment(data: AssignmentInput): Promise<AssignmentOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createAssignment,
  }
}

function useUpdateAssignment(): UseUpdateAssignment {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateAssignment['updateAssignment']>>>({ method: 'PUT' })

  function updateAssignment(id: AssignmentId, data: AssignmentOutput): Promise<AssignmentOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateAssignment,
  }
}

function useDeleteAssignment(): UseDeleteAssignment {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteAssignment['deleteAssignment']>>>({ method: 'DELETE' })

  function deleteAssignment(id: AssignmentId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteAssignment,
  }
}

function useExportAssignments(): UseExportAssignments {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportAssignments(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportAssignments,
  }
}

function useExportAssignmentsExtended(): UseExportAssignments {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportAssignments(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export/extended`, params: filters })
  }

  return {
    ...axios,
    exportAssignments,
  }
}

export {
  useGetAssignments,
  useGetAssignment,
  useGetAssignmentsBasic,
  useCreateAssignment,
  useUpdateAssignment,
  useDeleteAssignment,
  useExportAssignments,
  useExportAssignmentsExtended,
}
