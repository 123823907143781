var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "relative rounded-lg elevation-0"
  }, [_c('v-card-title', {
    staticClass: "pl-7 min-height"
  }, [_c('v-row', [_c('v-spacer'), _c('v-col', {
    staticClass: "justify-end mb-5 d-flex"
  }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_TRANSACTION_CREATE) && _vm.state.isTransactionPossible ? _c('v-btn', {
    staticClass: "ml-auto rounded-lg",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onCreateTransaction();
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.positionDetails.actions.addTransaction')) + " ")], 1) : _c('div', {
    staticClass: "mb-9"
  })], 1)], 1)], 1), _c('CommonTableView', {
    staticClass: "mb-0 mt-n5",
    attrs: {
      "is-common-view": false,
      "headers": _vm.constants.TABLE_HEADERS,
      "items": [].concat(_vm.position),
      "hide-default-footer": "",
      "loading": _vm.state.isLoadingAssignments
    },
    on: {
      "dblclick:row": function dblclickRow($event) {
        return _vm.listeners.onClickEdit(_vm.position);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onPositionDelete();
      },
      "click:info": function clickInfo($event) {
        return _vm.listeners.onClickInfo($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "header.currentlyAssigned",
      fn: function fn(_ref) {
        var header = _ref.header;
        return [_c('span', {
          staticClass: "absolute text-subtitle-1 font-weight-bold black--text"
        }, [_vm._v(_vm._s(_vm.$t('planning.workforcePlanning.positionDetails.cast')))]), _c('span', [_vm._v(_vm._s(header.text))])];
      }
    }, {
      key: "header.sourcingType",
      fn: function fn(_ref2) {
        var header = _ref2.header;
        return [_c('span', {
          staticClass: "absolute text-subtitle-1 font-weight-bold black--text"
        }, [_vm._v(_vm._s(_vm.$t('planning.workforcePlanning.positionDetails.position.title')))]), _c('span', [_vm._v(_vm._s(header.text))])];
      }
    }, {
      key: "plannedPerson",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm.functions.hasMultiplePersonsPlanned(item === null || item === void 0 ? void 0 : item.plannedPerson) ? _c('div', {
          staticClass: "d-flex flex-column"
        }, _vm._l(_vm.functions.convertStringToArray(item.plannedPerson), function (name, index) {
          return _c('span', {
            key: name + index
          }, [_vm._v(" " + _vm._s(name) + " ")]);
        }), 0) : _c('span', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.plannedPerson))])];
      }
    }, {
      key: "currentlyAssigned",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('DotIndicator', {
          attrs: {
            "color": "".concat(item.currentlyAssigned ? 'green' : 'red')
          }
        })];
      }
    }, {
      key: "positionStatus",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.functions.convertPositionStatus(item.positionStatus)) + " ")])];
      }
    }, {
      key: "contractualRelationships",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return _vm._l(_vm.functions.getAssignmentsDetails(item), function (assignment) {
          return _c('div', {
            key: assignment.id
          }, _vm._l(assignment.contractualRelationships, function (contractualRelationship) {
            return _c('span', {
              key: contractualRelationship
            }, [_vm._v(" " + _vm._s(contractualRelationship) + " ")]);
          }), 0);
        });
      }
    }, {
      key: "sourcingType",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.functions.convertSourcingType(item.sourcingType)) + " ")])];
      }
    }, {
      key: "approvals",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('DotIndicator', {
          attrs: {
            "color": "".concat(item.approvals.length ? 'green' : 'red')
          }
        })];
      }
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        var _vm$position, _vm$position$targetTr;

        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_TRANSACTION_DELETE) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.POSITION_DELETE) || (_vm$position = _vm.position) !== null && _vm$position !== void 0 && (_vm$position$targetTr = _vm$position.targetTransactions) !== null && _vm$position$targetTr !== void 0 && _vm$position$targetTr.length ? _c('div') : _vm._e()];
      },
      proxy: true
    }], null, true)
  }), _vm.state.isRowInfoDialogOpen ? _c('PositionCommitsDialog', {
    attrs: {
      "position": _vm.state.activePosition
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isRowInfoDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isRowInfoDialogOpen", $$v);
      },
      expression: "state.isRowInfoDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditTransactionDialogOpen ? _c('AddEditTransactionDialog', {
    attrs: {
      "position-to-edit": _vm.state.activePosition,
      "transaction-to-edit": _vm.state.transactionToEdit,
      "transaction-input-position": _vm.state.activePosition
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.$emit('invoke:init');
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditTransactionDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditTransactionDialogOpen", $$v);
      },
      expression: "state.isAddEditTransactionDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditPositionDialogOpen ? _c('AddEditPositionDialog', {
    attrs: {
      "position-to-edit": _vm.state.activePosition,
      "transaction-to-edit": _vm.state.transactionToEdit
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.$emit('invoke:init');
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditPositionDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditPositionDialogOpen", $$v);
      },
      expression: "state.isAddEditPositionDialogOpen"
    }
  }) : _vm._e(), _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseDeleteDialog();
      },
      "delete": function _delete($event) {
        return _vm.listeners.onDeletePosition();
      }
    },
    model: {
      value: _vm.state.isDeleteDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteDialogOpen", $$v);
      },
      expression: "state.isDeleteDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.deletePosition.text')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }