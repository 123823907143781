import { DATA_TYPE, FormField } from '@/utils/types/formField'
import { round } from 'lodash-es'

function convertCommaIntoDotInputWithForm<T>(formFields: FormField[], form: T): T {
  const updatedForm = { ...form }

  const numberFormFields = formFields.filter((field) => field.dataTyp === DATA_TYPE.NUMBER && form[field.value])

  numberFormFields.forEach((field) => {
    updatedForm[field.value] = Number(String(updatedForm[field.value]).replace(',', '.'))
  })

  return updatedForm
}

function convertDotIntoCommaInputWithForm<T>(formFields: FormField[], form: T): T {
  const updatedForm: T = { ...form }

  const numberFormFields = formFields.filter((field) => field.dataTyp === DATA_TYPE.NUMBER && form[field.value])

  numberFormFields.forEach((field) => {
    updatedForm[field.value] = String(updatedForm[field.value]).replace('.', ',')
  })

  return updatedForm
}

function convertCommaIntoDotInput(value: string): number {
  if (!value) return 0
  return Number(value.replace(',', '.'))
}

function convertDotIntoCommaInput(value: number, roundNumber?: number): string {
  if (Number.isInteger(value)) {
    return `${String(value)},0`
  } else if (roundNumber) {
    return round(value, roundNumber).toString().replace('.', ',')
  } else {
    return String(value).replace('.', ',')
  }
}

export {
  convertCommaIntoDotInputWithForm,
  convertDotIntoCommaInputWithForm,
  convertCommaIntoDotInput,
  convertDotIntoCommaInput,
}
