import { ComputedRef } from '@vue/composition-api'

enum FORM_FIELDS_ENUM {
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
  DATEPICKER = 'DATEPICKER',
  CHECKBOX = 'CHECKBOX',
  TEXTAREA = 'TEXTAREA',
}
enum DATA_TYPE {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  MULTISELECT = 'MULTISELECT',
}

interface FormField {
  value: string
  fieldType: keyof typeof FORM_FIELDS_ENUM
  dataTyp?: keyof typeof DATA_TYPE
  items?: ComputedRef<unknown> | unknown | undefined
  isLoading?: ComputedRef<boolean> | boolean
  isRequired: boolean
  isDisabled?: boolean
  rules: Array<(value: string) => string | boolean>
  dropdownTextProp?: string
  fieldOptions?: Record<string, string | boolean>[]
}

export { FORM_FIELDS_ENUM, FormField, DATA_TYPE }
