function convertToEuro(num: number, includeDecimals = true): string | null {
  if (Number.isInteger(num) || num) {
    const options = {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: includeDecimals ? 2 : 0,
      maximumFractionDigits: includeDecimals ? 2 : 0,
    }

    return new Intl.NumberFormat('de-DE', options).format(num)
  } else {
    return null
  }
}

export { convertToEuro }
