var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('div', {
    class: ["dot-".concat(_vm.color)]
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }