import { i18n } from '@/plugins/i18n'

export const isRequired = (value: string | number, name = ''): string | boolean => {
  if (Array.isArray(value)) {
    return !!value.length || (i18n.t('form.validation.required', { name }) as string)
  } else if (value === 0) {
    return true
  } else {
    return !!value || (i18n.t('form.validation.required', { name }) as string)
  }
}

export const isEmail = (value: string, name = ''): string | boolean => {
  // eslint-disable-next-line
  const pattern =
    /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/
  return value ? pattern.test(value) || (i18n.t('form.validation.email', { name }) as string) : true
}

export const isPhone = (value: string, name = ''): string | boolean => {
  // eslint-disable-next-line
  const pattern = /^(\+)(?:[0-9]{2,4})(\x20)([0-9]{2,6})(\x20)([0-9]{4,10})$/
  return value ? pattern.test(value) || (i18n.t('form.validation.phone', { name }) as string) : true
}

export function isZipCode(value: string): true | string {
  return value && typeof value === 'string'
    ? value.split('').length === 5 || (i18n.t('form.validation.addressZipCode') as string)
    : true
}

export const passwordsMatch = (password: string, repeatPassword: string): string | boolean =>
  password === repeatPassword || (i18n.t('form.validation.passwordsMatch') as string)

export const validRoleName = (value: string): boolean | string => {
  const pattern = /^[A-Z_]+$/
  return value && pattern.test(value)
}

export const isPasswordStrong = (value: string): boolean => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{20,})/
  return pattern.test(value)
}

export const isPasswordValid = (value: string): boolean | string | undefined => {
  const lowercaseLetter = /^(?=.*[a-z])/
  const uppercaseLetter = /^(?=.*[A-Z])/
  const numericCharacter = /^(?=.*[0-9])/
  const specialCharacter = /^(?=.*[!@#$%^&*])/
  const minimumLength = /^(?=.{20,})/
  if (!lowercaseLetter.test(value)) {
    return 'form.field.passwordValidationLowercase'
  } else if (!uppercaseLetter.test(value)) {
    return 'form.field.passwordValidationUppercase'
  } else if (!numericCharacter.test(value)) {
    return 'form.field.passwordValidationNumeric'
  } else if (!specialCharacter.test(value)) {
    return 'form.field.passwordValidationSpecial'
  } else if (!minimumLength.test(value)) {
    return 'form.field.passwordValidationLength20'
  } else {
    return true
  }
}

export function isCommaSeparatedFloat(value: string): string | boolean {
  const pattern = /^[0-9,]+$/
  return value ? pattern.test(value) || (i18n.t('form.validation.float') as string) : true
}

export function isValidDotNotationDate(value: string): true | string {
  const pattern = /^\d{2}.\d{2}.\d{4}$/
  return value ? pattern.test(value) || (i18n.t('form.validation.date') as string) : true
}

export function isValidDotNotationMonthDate(value: string): true | string {
  const pattern = /^\d{2}.\d{4}$/
  return pattern.test(value) || (i18n.t('form.validation.monthDate') as string)
}

export function isInteger(value: string | number): true | string {
  return Number.isInteger(Number(value)) ? true : (i18n.t('form.validation.integer') as string)
}

export function isValidDashNotationDate(value: string): true | string {
  const pattern = /^\d{4}-\d{2}-\d{2}$/
  return pattern.test(value) || (i18n.t('form.validation.date') as string)
}

export function isBanfNr(value: string): true | string {
  const pattern = /^\d{9}$/
  return value ? pattern.test(value) || (i18n.t('form.validation.banfNr') as string) : true
}

export function isBanfOrderNr(value: string): true | string {
  const pattern = /^\d{8}$/
  return value ? pattern.test(value) || (i18n.t('form.validation.banfOrderNr') as string) : true
}

export function isAccount(value: string): true | string {
  const pattern = /^\d{6}$/
  return value ? pattern.test(value) || (i18n.t('form.validation.account') as string) : true
}

export function isCorporateIdentifier(value: string): true | string {
  const pattern = /^\d{4}$/
  return value ? pattern.test(value) || (i18n.t('form.validation.corporateIdentifier') as string) : true
}

export function isBst(value: string): true | string {
  const pattern = /^[1-9]{6}$/
  return value ? pattern.test(value) || (i18n.t('form.validation.bst') as string) : true
}

export function isRkost(value: string): true | string {
  const pattern = /^[1-9]{5}$/
  return value ? pattern.test(value) || (i18n.t('form.validation.rkost') as string) : true
}

export function isFacilityConstruct(value: string): true | string {
  const pattern = /^\d{8}$/
  return value ? pattern.test(value) || (i18n.t('form.validation.facilityConstruct') as string) : true
}

export function isPercentageRange(value: string): true | string {
  const valueAsNumber = Number(String(value).replace(',', '.'))

  if (isNaN(valueAsNumber)) return i18n.t('form.validation.percentage') as string

  return valueAsNumber >= 0.0 && valueAsNumber <= 1.0 ? true : (i18n.t('form.validation.percentage') as string)
}

export function isYear(value: string): true | string {
  const pattern = /^[0-9]{4}$/

  return pattern.test(value) ? true : (i18n.t('form.validation.year') as string)
}

export function isEndingDateBeforeStartingDate(startingDate: string, endingDate: string): boolean | string {
  const [endingDay, endingMonth, endingYear] = endingDate.split('.')
  const [startingDay, startingMonth, startingYear] = startingDate.split('.')

  const ending = new Date(Number(endingYear), Number(endingMonth) - 1, Number(endingDay))
  const starting = new Date(Number(startingYear), Number(startingMonth) - 1, Number(startingDay))

  return ending > starting ? true : (i18n.t('form.validation.endingBeforeStarting') as string)
}
