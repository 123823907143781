
import { defineComponent } from '@vue/composition-api'

const COLORS = ['green', 'red', 'yellow', 'grey']

export default defineComponent({
  name: 'CommonDotIndicator',
  props: {
    color: {
      type: String,
      validator: (value: string) => COLORS.includes(value),
      required: true,
    },
  },
})
