import { i18n } from '@/plugins/i18n'

import { isOfType } from '@/utils/types/isOfType'
import { convertDotIntoCommaInput } from '@/utils/convertInput'
import { dateDashNotationToDot } from '@/utils/convertDate'
import { convertSourcingType } from '@/utils/convertSourcingType'

import { PositionOutput } from '@/api/types/position'
import { TimelineData } from './types'
import { TenderingOutput } from '@/api/types/tendering'
import { AssignmentOutput } from '@/api/types/assignment'

function createTimelineObject(
  position: PositionOutput,
  entity: PositionOutput | AssignmentOutput | TenderingOutput
): TimelineData {
  const timelineData = {
    x: position.group,
    y: [new Date(entity.start).getTime(), new Date(entity.end).getTime()],
    meta: entity,
    goals: isOfType<PositionOutput>(entity, 'approvals')
      ? [
          {
            value: new Date(position.end).getTime(),
            strokeColor: position.daysToNextPosition && position.daysToNextPosition > 3 ? 'red' : 'transparent',
            strokeWidth: 2,
          },
          {
            value: new Date(position.end).getTime(),
            strokeColor: !position.targetTransactions.length ? 'black' : 'transparent',
            strokeWidth: 2,
          },
          {
            value: new Date(position.start).getTime(),
            strokeColor: !position.sourceTransaction ? 'black' : 'transparent',
            strokeWidth: 2,
          },
        ]
      : [],
  }

  return timelineData
}

function getPositionsForDetailView(positionArray: PositionOutput[], corePosition: PositionOutput): PositionOutput[] {
  if (!positionArray.length) {
    return [corePosition]
  }

  const linkedPositions = positionArray.filter((position) => {
    if (!isOfType<PositionOutput>(position, 'targetTransactions')) return

    const selectedTargetTransactionId = corePosition.targetTransactions?.map(
      (targetTransaction) => targetTransaction.transactionId
    )

    const selectedSourceTransactionId = corePosition.sourceTransaction?.id

    const hasMatchingTargetTransactionId = position.targetTransactions.some((targetTransaction) =>
      selectedTargetTransactionId.includes(targetTransaction.transactionId)
    )

    const hasMatchingSourceTransactionId =
      position.sourceTransaction?.id && position.sourceTransaction.id === selectedSourceTransactionId

    const hasMatchingSourceTransactionAsTargetTransactionId = position.targetTransactions.some(
      (targetTransaction) => targetTransaction.transactionId === selectedSourceTransactionId
    )

    const hasMatchingTargetTransactionAsSourceTransactionId =
      position.sourceTransaction?.id && selectedTargetTransactionId.includes(position.sourceTransaction.id)

    return (
      hasMatchingTargetTransactionId ||
      hasMatchingSourceTransactionId ||
      hasMatchingSourceTransactionAsTargetTransactionId ||
      hasMatchingTargetTransactionAsSourceTransactionId
    )
  })

  return [...linkedPositions]
}

function getDateRange(minMax: 'min' | 'max'): number {
  const date = new Date()
  minMax === 'min' ? date.setFullYear(date.getFullYear() - 1, 0, 1) : date.setFullYear(date.getFullYear() + 5, 0, 1)
  return date.getTime()
}

function getTooltipContent(entity: AssignmentOutput | PositionOutput | TenderingOutput): string {
  if (isOfType<AssignmentOutput>(entity, 'person') || isOfType<PositionOutput>(entity, 'group')) {
    return `
    <ul class="tooltip__list">
      <li
        <div/>
        <b>${i18n.t('planning.workforcePlanning.positionTimeline.id')}: </b>
        ${entity.id}
      </li>
      <li
        <div/>
        <b>${i18n.t('planning.workforcePlanning.positionTimeline.sourcing')}: </b>
        ${
          isOfType<PositionOutput>(entity, 'group')
            ? convertSourcingType(entity.sourcingType)
            : entity.contractualRelationships
        }
      </li>
      <li
        <div/>
        <b>${i18n.t('planning.workforcePlanning.positionTimeline.person')}: </b>
        ${isOfType<PositionOutput>(entity, 'group') ? entity.plannedPerson : entity.person?.name}
      </li>
      <li
        <div/>
        <b>${i18n.t('planning.workforcePlanning.positionTimeline.scope')}: </b>
        ${convertDotIntoCommaInput(entity.scope, 2)}
      </li>
      ${
        isOfType<AssignmentOutput>(entity, 'suppliers')
          ? `<li
          <div/>
          <b>${i18n.t('planning.workforcePlanning.positionTimeline.supplier')}: </b>
          ${entity.suppliers.map((supplier) => supplier.name).join(', ')}
        </li>`
          : ''
      }
      <li
        <div/>
        <b>${i18n.t('planning.workforcePlanning.positionTimeline.professionalUnit')}: </b>
        ${entity.professionalUnit?.name}
      </li>
      <li>
        <b>${i18n.t('planning.workforcePlanning.positionTimeline.profile')}: </b>
        ${entity.profile?.name}
      </li>
      <li>
        <b>${i18n.t('planning.workforcePlanning.positionTimeline.start')}: </b>
        ${dateDashNotationToDot(entity.start)}
      </li>
      <li>
        <b>${i18n.t('planning.workforcePlanning.positionTimeline.end')}: </b>
        ${dateDashNotationToDot(entity.end)}
      </li>
    </ul>`
  } else {
    const profileName = entity.profile?.name.split('W')
    const pfw = profileName?.[profileName.length - 1]
    const applicationCount = entity.applications.length

    return `
        <ul class="tooltip__list">
          <li>
            <b>${i18n.t('planning.workforcePlanning.positionTimeline.status')}: </b>
            ${
              applicationCount
                ? i18n.t('planning.workforcePlanning.positionTimeline.application')
                : i18n.t('planning.workforcePlanning.positionTimeline.tendering')
            }
          </li>
          <li>
            <b>${i18n.t('planning.workforcePlanning.positionTimeline.applicationCount')}: </b>
            ${applicationCount}
          </li>
          <li>
            <b>${i18n.t('planning.workforcePlanning.positionTimeline.tenderingProfileNumber')}: </b>
            ${pfw}
          </li>
          <li>
            <b>${i18n.t('planning.workforcePlanning.positionTimeline.start')}: </b>
            ${dateDashNotationToDot(entity.start)}
          </li>
          <li>
            <b>${i18n.t('planning.workforcePlanning.positionTimeline.end')}: </b>
            ${dateDashNotationToDot(entity.end)}
          </li>
        </ul>`
  }
}

export { createTimelineObject, getPositionsForDetailView, getDateRange, getTooltipContent }
