
import { defineComponent, reactive, ref } from '@vue/composition-api'
import {
  mdiDelete,
  mdiInformationOutline,
  mdiTextBoxCheckOutline,
  mdiContentDuplicate,
  mdiNotebookEditOutline,
  mdiPaperclip,
  mdiPencil,
  mdiDownload,
  mdiCheck,
  mdiClose,
} from '@mdi/js'

import DotIndicator from '@/components/common/CommonDotIndicator.vue'

import { useCapacity } from '@/views/planning/views/capacity/useCapacity'

import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { dateDashNotationToDot } from '@/utils/convertDate'
import { convertToEuro } from '@/utils/convertCurrency'
import { convertDotIntoCommaInput } from '@/utils/convertInput'

import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'CommonTableView',
  components: {
    DotIndicator,
    CommonDeleteIconBtn: () => import('@/components/common/CommonDeleteIconBtn.vue'),
  },
  inheritAttrs: false,
  props: {
    withNotesAndTasks: {
      type: Boolean,
      default: false,
    },
    withInfo: {
      type: Boolean,
      default: true,
    },
    withFiles: {
      type: Boolean,
      default: false,
    },
    withDuplicate: {
      type: Boolean,
      default: false,
    },
    withEdit: {
      type: Boolean,
      default: false,
    },
    withDownload: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 2,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isCommonView: {
      type: Boolean,
      default: true,
    },
  },
  setup: () => {
    const { capacityCols } = useCapacity()

    const isItemsPerPageDefault = ref<boolean>(true)

    function onUpdateItemsPerPage(itemsPerPage: number) {
      isItemsPerPageDefault.value = itemsPerPage === 10
    }

    return reactive({
      icons: {
        mdiDelete,
        mdiInformationOutline,
        mdiTextBoxCheckOutline,
        mdiNotebookEditOutline,
        mdiContentDuplicate,
        mdiPaperclip,
        mdiPencil,
        mdiDownload,
        mdiCheck,
        mdiClose,
      },
      constants: {
        Rights,
      },
      state: {
        capacityCols,
        isItemsPerPageDefault,
      },
      functions: {
        dateDashNotationToDot,
        convertDotIntoCommaInput,
        convertToEuro,

        hasSufficientRights,
      },
      listeners: {
        onUpdateItemsPerPage,
      },
    })
  },
})
