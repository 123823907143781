import { camelCase as toCamelCase } from 'lodash-es'

import { i18n } from '@/plugins/i18n'
import { POSITIONSTATUS } from '@/views/planning/views/workforcePlanning/types'
import { TranslateResult } from 'vue-i18n'

function convertPositionStatus(status: string): TranslateResult | undefined {
  switch (status) {
    case POSITIONSTATUS.ACTIVE:
      return i18n.t(`planning.workforcePlanning.form.position.status.${toCamelCase(POSITIONSTATUS.ACTIVE)}`)

    case POSITIONSTATUS.DRAFT:
      return i18n.t(`planning.workforcePlanning.form.position.status.${toCamelCase(POSITIONSTATUS.DRAFT)}`)

    case POSITIONSTATUS.ARCHIVED:
      return i18n.t(`planning.workforcePlanning.form.position.status.${toCamelCase(POSITIONSTATUS.ARCHIVED)}`)

    case POSITIONSTATUS.FREEZE:
      return i18n.t(`planning.workforcePlanning.form.position.status.${toCamelCase(POSITIONSTATUS.FREEZE)}`)

    default:
      return
  }
}

export { convertPositionStatus }
