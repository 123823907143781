import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import { PromiseType } from '@/utils/types/PromiseType'
import {
  PositionOutput,
  PositionId,
  PositionInput,
  UseCreatePosition,
  UseDeletePosition,
  UseGetPositions,
  UseGetPositionsBasic,
  UseUpdatePosition,
  UseGetPosition,
  UseCreateApproval,
  ApprovalInput,
  UseDeleteApproval,
  UseExportPositions,
  UseGetPositionsComparisonKpi,
  PositionComparisonKpiOutput,
} from '@/api/types/position'
import { BasicEntity } from './types/misc'
import { OptionalFiltersParam } from '@/composables/types/useFilter'

const VERSION = 'v1'
const RESOURCE = 'positions'

function useGetPositions(): UseGetPositions {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetPositions['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetPosition(): UseGetPosition {
  const axios = useAxios<PromiseType<ReturnType<UseGetPosition['getPosition']>>>({ method: 'GET' })

  function getPosition(id: PositionId): Promise<PositionOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getPosition,
  }
}

function useGetPositionsBasic(): UseGetPositionsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreatePosition(): UseCreatePosition {
  const axios = useAxios<PromiseType<ReturnType<UseCreatePosition['createPosition']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createPosition(data: PositionInput): Promise<PositionOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createPosition,
  }
}

function useUpdatePosition(): UseUpdatePosition {
  const axios = useAxios<PromiseType<ReturnType<UseUpdatePosition['updatePosition']>>>({ method: 'PUT' })

  function updatePosition(id: PositionId, data: PositionOutput): Promise<PositionOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updatePosition,
  }
}

function useDeletePosition(): UseDeletePosition {
  const axios = useAxios<PromiseType<ReturnType<UseDeletePosition['deletePosition']>>>({ method: 'DELETE' })

  function deletePosition(id: PositionId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deletePosition,
  }
}

function useExportPositions(): UseExportPositions {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportPositions(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportPositions,
  }
}

function useExportPositionsExtended(): UseExportPositions {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportPositions(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export/extended`, params: filters })
  }

  return {
    ...axios,
    exportPositions,
  }
}

function useCreateApproval(): UseCreateApproval {
  const axios = useAxios<PromiseType<ReturnType<UseCreateApproval['createApproval']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createApproval(positionId: PositionId, data: ApprovalInput): Promise<PositionOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${positionId}/addApproval`, data })
  }

  return {
    ...axios,
    createApproval,
  }
}

function useDeleteApproval(): UseDeleteApproval {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteApproval['deleteApproval']>>>({ method: 'DELETE' })

  function deleteApproval(positionId: PositionId, approvalId: number): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${positionId}/removeApproval/${approvalId}` })
  }

  return {
    ...axios,
    deleteApproval,
  }
}

function useGetPositionsComparisonKpi(): UseGetPositionsComparisonKpi {
  const axios = useAxios<PositionComparisonKpiOutput>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/comparison-kpi` })
  return axios
}

export {
  useGetPositions,
  useGetPosition,
  useGetPositionsBasic,
  useCreatePosition,
  useUpdatePosition,
  useDeletePosition,
  useExportPositions,
  useExportPositionsExtended,
  useCreateApproval,
  useDeleteApproval,
  useGetPositionsComparisonKpi,
}
