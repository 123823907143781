
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep, uniq } from 'lodash-es'

import VueApexCharts from 'vue-apexcharts'

import { convertDotIntoCommaInput } from '@/utils/convertInput'

import { useGetAssignments } from '@/api/assignment'
import { useGetPositions } from '@/api/position'
import { useGetTenderings } from '@/api/tendering'

import { PositionOutput } from '@/api/types/position'
import { ApexOptions } from 'apexcharts'
import { AssignmentOutput } from '@/api/types/assignment'
import { TenderingOutput } from '@/api/types/tendering'
import { isOfType } from '@/utils/types/isOfType'
import { useGetEnumItemsBasic } from '@/api/enumItem'
import { TENDERING_STATUS } from '@/views/acquisition/views/tenderings/types'
import { TimelineData, TimeScaleData } from '../types'
import { createTimelineObject, getDateRange, getPositionsForDetailView, getTooltipContent } from '../utils'

export default defineComponent({
  name: 'WorkforcePlanningTimeline',
  components: {
    Apexchart: VueApexCharts,
  },
  props: {
    position: {
      type: Object as PropType<PositionOutput>,
      default: null,
    },
    positions: {
      type: Array as PropType<PositionOutput[]>,
      default: null,
    },
    scenarioPositions: {
      type: Array as PropType<PositionOutput[]>,
      default: null,
    },
    showAcquisition: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, { root, emit }) => {
    const isPositionDetailView = computed(() => Boolean(props.position))

    const positionsForDetailView = computed(() => {
      return getPositionsForDetailView(positions.value, props.position)
    })

    const positionIds = computed(() => {
      if (isPositionDetailView.value) {
        return positionsForDetailView.value.map((pos) => pos.id).join(',')
      } else {
        return filteredPositions.value.map((pos) => pos.id).join(',')
      }
    })

    const filteredPositions = computed<PositionOutput[]>(() =>
      cloneDeep(props.positions ?? []).sort((a, b) => (a.group > b.group ? 1 : -1))
    )

    const filteredScenarioPositions = computed<PositionOutput[]>(() =>
      cloneDeep(props.scenarioPositions ?? []).sort((a, b) => (a.group > b.group ? 1 : -1))
    )

    const positionGroups = computed(() => uniq(filteredPositions.value.map((pos) => pos.group)))

    const scenarioPositionGroups = computed(() => uniq(filteredScenarioPositions.value.map((pos) => pos.group)))

    const { exec: getPositions, data: positions, isLoading: isLoadingPositions } = useGetPositions()
    const { exec: getAssignments, data: assignments, isLoading: isLoadingAssignments } = useGetAssignments()
    const { exec: getTenderings, data: tenderings, isLoading: isLoadingTenderings } = useGetTenderings()
    const { exec: getEnumItemsBasic, data: tenderingStatus } = useGetEnumItemsBasic()

    // load all positions in positionDetailsView
    watch(
      () => props.position,
      async () => {
        if (isPositionDetailView.value) {
          let positionTransactionTargetIds: number[]
          let positionTransactionSourceId: number | undefined

          positionTransactionTargetIds =
            props.position?.targetTransactions?.map((targetTransaction) => targetTransaction.transactionId) ?? []

          positionTransactionSourceId = props.position.sourceTransaction?.id

          const positionTransactionIds = [...positionTransactionTargetIds, ...[positionTransactionSourceId]].join(',')

          if (positionTransactionIds.length) {
            await getPositions({ params: { allTransactionIds: positionTransactionIds, size: 9999 } })
          }
        }
      },
      { immediate: true }
    )

    // load specific assignments for better performance
    watch(
      () => positionIds.value,
      async () => {
        if (positionIds.value.length) {
          await getAssignments({ params: { positionIds: positionIds.value, size: 9999 } })
        }
      },
      { immediate: true }
    )

    // load tenderings depending on switch filter
    watch(
      () => props.showAcquisition,
      async () => {
        if (isPositionDetailView.value || props.showAcquisition) {
          await getEnumItemsBasic({ params: { enumItemAssignment: 'TENDERING_STATUS' } })

          if (positionIds.value && tenderingStatus.value) {
            const filteredTenderingStatusIds = tenderingStatus.value
              .filter((status) => status.name !== TENDERING_STATUS.CLOSED)
              .map((status) => status.id)
              .join(',')

            await getTenderings({
              params: { positionIds: positionIds.value, statusIds: filteredTenderingStatusIds, size: 9999 },
            })
          }
        } else {
          tenderings.value = []
        }
      },
      { immediate: true }
    )

    const series = computed<ApexOptions['series']>(() => {
      const positionData: TimelineData[] = []
      const assignmentData: TimelineData[] = []
      const tenderingData: TimelineData[] = []
      const scenarioPositionData: TimelineData[] = []

      function addAssignmentTimelineData(timelinePosition: PositionOutput): void {
        assignments.value.map((assignment) => {
          if (assignment.position?.id === timelinePosition.id) {
            const timelineAssignmentData = createTimelineObject(timelinePosition, assignment)

            assignmentData.push(timelineAssignmentData)
          }
        })
      }

      function addTenderingTimelineData(timelinePosition: PositionOutput): void {
        tenderings.value.map((tendering) => {
          if (tendering.position?.id === timelinePosition.id) {
            const timelineTenderingData = createTimelineObject(timelinePosition, tendering)

            tenderingData.push(timelineTenderingData)
          }
        })
      }

      // positionDetailView timeline
      if (isPositionDetailView.value) {
        const timelinePositions = cloneDeep(positionsForDetailView.value)
        timelinePositions
          .sort((a, b) => (a.group > b.group ? 1 : -1))
          .map((position: PositionOutput) => {
            // add position
            const timelinePositionData = createTimelineObject(position, position)

            positionData.push(timelinePositionData)

            // add assignments
            addAssignmentTimelineData(position)

            // add tenderings
            addTenderingTimelineData(position)
          })

        // overview timeline
      } else {
        // iterate over groups

        positionGroups.value.map((positionGroup) => {
          filteredPositions.value
            .filter((position) => position.group === positionGroup)
            .map((position) => {
              // filter positions to concerning group and add position data

              const timelinePositionData = createTimelineObject(position, position)

              positionData.push(timelinePositionData)

              // add assignment data
              addAssignmentTimelineData(position)

              // add tendering data
              addTenderingTimelineData(position)
            })
        })

        scenarioPositionGroups.value.map((scenarioPositionGroup) => {
          // filter positions to concerning group and add scenarioPositions

          filteredScenarioPositions.value
            .filter((scenarioPosition) => scenarioPosition.group === scenarioPositionGroup)

            .map((scenarioPosition) => {
              const timelineScenarioPositionData = createTimelineObject(scenarioPosition, scenarioPosition)

              scenarioPositionData.push(timelineScenarioPositionData)
            })
        })
      }

      // use fixed colors for series dataset to avoid color changes on filter
      const updatedSeries: ApexOptions['series'] = [
        {
          name: root.$t('planning.workforcePlanning.positionTimeline.position') as string,
          data: positionData,
          color: root.$vuetify.theme.currentTheme.primary?.toString(),
        },
        {
          name: root.$t('planning.workforcePlanning.positionTimeline.assignment') as string,
          data: assignmentData,
          color: root.$vuetify.theme.currentTheme.dbGreen?.toString(),
        },
      ]

      if (scenarioPositionData.length) {
        updatedSeries.unshift({
          name: root.$t('planning.workforcePlanning.positionTimeline.scenarioPosition') as string,
          data: scenarioPositionData,
          color: root.$vuetify.theme.currentTheme.dbOrange?.toString(),
        })
      }

      if (tenderingData.length) {
        updatedSeries.push({
          name: root.$t('planning.workforcePlanning.positionTimeline.acquisition') as string,
          data: tenderingData,
          color: root.$vuetify.theme.currentTheme.dbLightGreen?.toString(),
        })
      }

      return updatedSeries
    })

    function onDblClick(metaData: PositionOutput | AssignmentOutput | TenderingOutput): void {
      if (isOfType<AssignmentOutput>(metaData, 'contracts')) {
        root.$router.push({
          name: 'person-profile',
          params: { id: String(metaData.person?.id) },
        })
      } else if (isOfType<PositionOutput>(metaData, 'group')) {
        root.$router.push({
          name: 'position-details',
          params: { positionId: String(metaData.id) },
        })
      } else if (isOfType<TenderingOutput>(metaData, 'applications')) {
        if (metaData.applications.length) {
          const applicationIds = metaData.applications.map((application) => application.id).join(',')
          root.$router.push({
            name: 'acquisition-applications',
            params: { ids: applicationIds },
            query: { ids: applicationIds },
          })
        } else {
          root.$router.push({
            name: 'acquisition-tenderings',
            params: { ids: String(metaData.id) },
            query: { ids: String(metaData.id) },
          })
        }
      } else return
    }

    const highlightedPositions = ref<PositionOutput[] | null>(null)

    const chartTimeScale = ref<TimeScaleData[]>([])

    const chartOptions = computed<ApexOptions>(() => {
      return {
        chart: {
          type: 'rangeBar',
          events: {
            // set initial timespan on resetZoom
            beforeResetZoom: () => {
              return {
                xaxis: {
                  min: getDateRange('min'),
                  max: getDateRange('max'),
                },
              }
            },
            mounted: (chartContext) => {
              chartTimeScale.value = chartContext.w.globals.timescaleLabels
            },
            dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
              const selectedData = w.config.series[seriesIndex].data[dataPointIndex].meta

              if (event?.detail === 2) {
                // forward to positionDetails on dblClick
                if (props.position && props.position.id === selectedData.id) return

                // workaround for pagination error because of apexchart-bug: https://github.com/apexcharts/apexcharts.js/issues/1790
                setTimeout(() => {
                  onDblClick(selectedData)
                }, 5)
              } else if (event?.detail === 1 && !isPositionDetailView.value) {
                if (!isOfType<PositionOutput>(selectedData, 'scenario')) return

                emit('select:position', selectedData)

                // mark all positions onClick that are linked to the selected with any transactions if it's not the positionDetail (same sourceTransaction, same targetTransaction, sourceTransaction matches targetTransaction and targetTransaction matches sourceTransaction)

                const displayedPositions = w.config.series[seriesIndex].data.map((data: TimelineData) => data.meta)

                const linkedPositions = getPositionsForDetailView(displayedPositions, selectedData)

                // deselect old data
                if (highlightedPositions.value) {
                  highlightedPositions.value?.map((highlighted: PositionOutput) => {
                    const index = w.config.series[seriesIndex].data.findIndex(
                      (data: TimelineData) => highlighted.id === data.meta.id
                    )
                    chartContext.toggleDataPointSelection(seriesIndex, index)
                  })
                }

                // select new data
                linkedPositions
                  .filter((position) => position.id !== selectedData.id)
                  .map((position: PositionOutput) => {
                    const index = w.config.series[seriesIndex].data.findIndex(
                      (data: TimelineData) => position.id === data.meta.id
                    )
                    chartContext.toggleDataPointSelection(seriesIndex, index)
                  })

                highlightedPositions.value = linkedPositions
              }
            },
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '20px',
            dataLabels: {
              position: 'center',
            },
          },
        },

        // seperate bars
        stroke: {
          colors: ['transparent'],
          width: 0,
        },

        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          offsetY: -1,
          // display only the scope or additionally the person if it's an assignment
          formatter: (_, { seriesIndex, dataPointIndex, w }) => {
            const meta = w.config.series[seriesIndex].data[dataPointIndex].meta

            if (isOfType<TenderingOutput>(w.config.series[seriesIndex].data[dataPointIndex].meta, 'applications')) {
              const profileName = w.config.series[seriesIndex].data[dataPointIndex].meta.profile.name.split('W')
              const pfw = profileName[profileName.length - 1]
              const applicationCount = w.config.series[seriesIndex].data[dataPointIndex].meta.applications.length
              if (applicationCount) {
                return `
                  ${root.$t(
                    'planning.workforcePlanning.positionTimeline.application'
                  )} - PFW: ${pfw} (${applicationCount})`
              } else {
                return `${root.$t('planning.workforcePlanning.positionTimeline.tendering')} - PFW: ${pfw}`
              }
            }

            if (!meta.scope) return ''

            if (
              isOfType<PositionOutput>(w.config.series[seriesIndex].data[dataPointIndex].meta, 'targetTransactions')
            ) {
              return `Id: ${meta.id} - ${convertDotIntoCommaInput(meta.scope, 2)}`
            } else {
              return `${meta.person.name.split('(')[0]} - ${convertDotIntoCommaInput(meta.scope, 2)}`
            }
          },
        },

        // allow more than one selection to mark all linked positions
        states: {
          active: {
            allowMultipleDataPointsSelection: true,
            filter: {
              type: 'darken',
              value: 0.2,
            },
          },
          hover: {
            filter: {
              type: 'none',
            },
          },
        },

        xaxis: {
          type: 'datetime',
          min: getDateRange('min'),
          max: getDateRange('max'),
        },

        yaxis: {
          labels: {
            style: {
              fontWeight: 600,
            },
            formatter: (val) => {
              const dataLabel = String(val).split(' - ').slice(0, 2)
              const team = dataLabel[0]
              const profile = dataLabel[1]

              if (String(profile).length > 20) {
                //if role has to many chars to display, add third line
                const sliceIndex = profile.lastIndexOf(' ')
                const profileLineOne = String(profile).slice(0, sliceIndex)

                //cut out the space
                const profileLineTwo = String(profile).slice(sliceIndex + 1)

                const formattedProfileLineOne = profileLineTwo ? `${profileLineOne}` : profileLineOne

                return [team, formattedProfileLineOne, profileLineTwo]
              }

              return dataLabel
            },
            align: 'left',
            offsetX: -10,
          },
        },

        annotations: {
          // mark todays date and initally displayed years
          xaxis: [
            ...chartTimeScale.value.map((timeScaleData: TimeScaleData) => {
              return {
                x: new Date(timeScaleData.dateString).getTime(),
                borderColor: 'rgb(177, 185, 196)',
                label: {
                  text: timeScaleData.value,
                  offsetY: -8,
                  orientation: 'horizontal',
                },
              }
            }),
            {
              x: new Date().getTime(),
              borderColor: root.$vuetify.theme.currentTheme.primary?.toString(),
              label: {
                text: new Date().toLocaleDateString().replaceAll('/', '.'),
                offsetY: -24,
                orientation: 'horizontal',
              },
            },
          ],
        },

        fill: {
          colors: [
            ({ seriesIndex, dataPointIndex, w }) => {
              const metaData = w.config.series[seriesIndex].data[dataPointIndex].meta

              if (
                isPositionDetailView.value &&
                isOfType<PositionOutput>(metaData, 'group') &&
                props.position.id !== metaData.id
              ) {
                return root.$vuetify.theme.currentTheme.accent?.toString()
              } else if (isOfType<PositionOutput>(metaData, 'group')) {
                return root.$vuetify.theme.currentTheme.primary?.toString()
              } else if (isOfType<AssignmentOutput>(metaData, 'person')) {
                return root.$vuetify.theme.currentTheme.dbGreen?.toString()
              }
            },
          ],
          type: 'solid',
          opacity: 0.8,
        },

        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },

        tooltip: {
          followCursor: true,
          custom: ({ seriesIndex, dataPointIndex, w }) => {
            const metaData = w.config.series[seriesIndex].data[dataPointIndex].meta
            const tooltipContent = getTooltipContent(metaData)
            return tooltipContent
          },
        },
      }
    })

    const hasDataLoaded = computed(
      () =>
        props.position ||
        props.positions?.length ||
        isLoadingPositions.value ||
        isLoadingAssignments.value ||
        isLoadingTenderings.value
    )

    return reactive({
      state: {
        hasDataLoaded,

        series,

        chartOptions,

        positionGroups,

        isPositionDetailView,
      },
    })
  },
})
