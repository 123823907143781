import { AxiosError } from 'axios'

import { i18n } from '@/plugins/i18n'

import { useNotify } from '@/store'

const { addNotification } = useNotify()

function handleError(error: unknown): void {
  if (error instanceof AxiosError && error.response && error.response?.status >= 400 && error.response?.status < 500) {
    addNotification({
      text: error.response?.data?.message || i18n.t('misc.genericClientSideErrorMessage'),
      type: 'error',
    })
  } else {
    throw error
  }
}

export { handleError }
