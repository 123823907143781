import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { OptionalFiltersParam } from '@/composables/types/useFilter'

import {
  CapacityProfUnitExtensionId,
  CapacityProfUnitExtensionInput,
  CapacityProfUnitExtensionOutput,
  UseCreateCapacityProfUnitExtension,
  UseDeleteCapacityProfUnitExtension,
  UseExportCapacityProfUnitExtensions,
  UseGetCapacityProfUnitExtensions,
  UseUpdateCapacityProfUnitExtension,
} from './types/capacityProfUnitExtension'

import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'capacity-professional-unit-extension'

function useGetCapacityProfUnitExtensions(): UseGetCapacityProfUnitExtensions {
  const axios = useAxiosPaginated<CapacityProfUnitExtensionOutput>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useCreateCapacityProfUnitExtension(): UseCreateCapacityProfUnitExtension {
  const axios = useAxios<
    PromiseType<ReturnType<UseCreateCapacityProfUnitExtension['createCapacityProfUnitExtension']>>
  >({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createCapacityProfUnitExtension(
    data: CapacityProfUnitExtensionInput
  ): Promise<CapacityProfUnitExtensionOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createCapacityProfUnitExtension,
  }
}

function useUpdateCapacityProfUnitExtension(): UseUpdateCapacityProfUnitExtension {
  const axios = useAxios<
    PromiseType<ReturnType<UseUpdateCapacityProfUnitExtension['updateCapacityProfUnitExtension']>>
  >({ method: 'PUT' })

  function updateCapacityProfUnitExtension(
    id: CapacityProfUnitExtensionId,
    data: CapacityProfUnitExtensionOutput
  ): Promise<CapacityProfUnitExtensionOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateCapacityProfUnitExtension,
  }
}

function useDeleteCapacityProfUnitExtension(): UseDeleteCapacityProfUnitExtension {
  const axios = useAxios<
    PromiseType<ReturnType<UseDeleteCapacityProfUnitExtension['deleteCapacityProfUnitExtension']>>
  >({ method: 'DELETE' })

  function deleteCapacityProfUnitExtension(id: CapacityProfUnitExtensionId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteCapacityProfUnitExtension,
  }
}

function useExportCapacityProfUnitExtensions(): UseExportCapacityProfUnitExtensions {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportCapacityProfUnitExtensions(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportCapacityProfUnitExtensions,
  }
}

export {
  useGetCapacityProfUnitExtensions,
  useCreateCapacityProfUnitExtension,
  useUpdateCapacityProfUnitExtension,
  useDeleteCapacityProfUnitExtension,
  useExportCapacityProfUnitExtensions,
}
