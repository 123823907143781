import { useAuthGetters } from '@/store'

import { Rights } from '@/api/types/right'

function hasSufficientRights(right: Rights): boolean {
  const { userRights } = useAuthGetters()

  return userRights.value.includes(right)
}

export { hasSufficientRights }
