import { isValidDotNotationDate, isValidDashNotationDate, isValidDotNotationMonthDate } from '@/utils/validation'
import { DATA_TYPE, FormField } from '@/utils/types/formField'

function dateDotNotationToDashWithForm<T>(formFields: FormField[], form: T): T {
  const updatedForm = { ...form }

  const dateFormFields = formFields.filter((field) => field.dataTyp === DATA_TYPE.DATE && form[field.value])

  dateFormFields.forEach((field) => {
    updatedForm[field.value] = isValidDotNotationDate(updatedForm[field.value])
      ? updatedForm[field.value]?.split('.').reverse().join('-')
      : updatedForm[field.value]
  })

  return updatedForm
}

/**
 * @param {string} date - A date in the format of DD.MM.YYYY
 * @returns {string} - A Date in the format of YYYY-MM-DD
 */
function dateDotNotationToDash(date: string): string {
  if ((date && isValidDotNotationDate(date) === true) || isValidDotNotationMonthDate(date) === true) {
    return date.split('.').reverse().join('-')
  } else {
    return date
  }
}

/**
 * @param {string} date - A date in the format of YYYY-MM-DD
 * @returns {string} - A Date in the format of DD.MM.YYYY
 */
function dateDashNotationToDot(date: string): string {
  if (date) {
    // check if of format 'YYYY-MM-DDTHH-MM ...'
    if (date.split('T').length) {
      return date.split('T')[0].split('-').reverse().join('.')
    } else {
      return date.split('-').reverse().join('.')
    }
  } else {
    return date
  }
}

function dateDashNotationToDotWithForm<T>(formFields: FormField[], form: T): T {
  const updatedForm = { ...form }

  const dateFormFields = formFields.filter((field) => field.dataTyp === DATA_TYPE.DATE && form[field.value])

  dateFormFields.forEach((field) => {
    updatedForm[field.value] = isValidDashNotationDate(updatedForm[field.value])
      ? updatedForm[field.value]?.split('-').reverse().join('.')
      : updatedForm[field.value]
  })

  return updatedForm
}

export { dateDotNotationToDashWithForm, dateDotNotationToDash, dateDashNotationToDot, dateDashNotationToDotWithForm }
