
import { PropType, computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiArrowLeftThin, mdiDelete, mdiPencil, mdiPlus } from '@mdi/js'
import { useNotify } from '@/store'
import { cloneDeep } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'
import DotIndicator from '@/components/common/CommonDotIndicator.vue'

import { handleError } from '@/utils/handleError'
import { convertToEuro } from '@/utils/convertCurrency'
import { convertDotIntoCommaInput } from '@/utils/convertInput'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { convertSourcingType } from '@/utils/convertSourcingType'

import { useDeletePosition } from '@/api/position'

import { Rights } from '@/api/types/right'
import { PositionOutput } from '@/api/types/position'
import { BasicEntity } from '@/api/types/misc'
import { convertPositionStatus } from '@/utils/convertPositionStatus'
import { useGetAssignments } from '@/api/assignment'
import { AssignmentOutput } from '@/api/types/assignment'

export default defineComponent({
  name: 'PositionDetailsTable',
  components: {
    CommonTableView,
    DotIndicator,
    AddEditTransactionDialog: () =>
      import('@/views/planning/views/workforcePlanning/components/AddEditTransactionDialog.vue'),
    AddEditPositionDialog: () =>
      import('@/views/planning/views/workforcePlanning/components/AddEditPositionDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    PositionCommitsDialog: () =>
      import('@/views/planning/views/workforcePlanning/components/PositionCommitsDialog.vue'),
  },
  props: {
    position: {
      type: Object as PropType<PositionOutput>,
      default: null,
    },
  },

  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()
    const { deletePosition: deletePositionXhr } = useDeletePosition()

    const isDeleteDialogOpen = ref(false)
    const isAddEditPositionDialogOpen = ref(false)
    const isAddEditTransactionDialogOpen = ref(false)
    const transactionToEdit = ref<null | BasicEntity>(null)
    const activePosition = ref<PositionOutput | null>(null)

    const isTransactionPossible = computed(() => {
      if (
        props.position?.limited ||
        props.position?.positionStatus !== 'ACTIVE' ||
        !props.position.availableScopeForTransaction
      ) {
        return false
      } else {
        return true
      }
    })

    const { exec: getAssignments, data: assignments, isLoading: isLoadingAssignments } = useGetAssignments()

    watch(
      () => props.position,
      async () => {
        if (props.position) {
          await getAssignments({
            params: {
              size: 9999,
              positionIds: props.position.id,
            },
          })
        }
      },
      { immediate: true }
    )

    const TABLE_HEADERS = [
      {
        text: root.$t('planning.workforcePlanning.table.col.title.id'),
        value: 'id',
        sortable: true,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.assigned'),
        value: 'currentlyAssigned',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.plannedPerson'),
        value: 'plannedPerson',
        sortable: false,
        width: 150,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.contractualRelationships'),
        value: 'contractualRelationships',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.sourcingType'),
        value: 'sourcingType',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.secondLevelProfessionalUnit'),
        value: 'secondLevelProfessionalUnit',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.professionalUnit'),
        value: 'professionalUnit',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.profile'),
        value: 'profile',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.scope'),
        value: 'scope',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.start'),
        value: 'start',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.end'),
        value: 'end',
        sortable: false,
      },
      hasSufficientRights(Rights.FINANCIAL_READ) && {
        text: root.$t('planning.workforcePlanning.table.col.title.hourlyRate'),
        value: 'hourlyRate',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.positionStatus'),
        value: 'positionStatus',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.budgetSourceOrgUnit'),
        value: 'budgetSourceOrgUnit.name',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.unlimited'),
        value: 'limited',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.approvals'),
        value: 'approvals',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const isRowInfoDialogOpen = ref(false)

    function onClickInfo(position: PositionOutput): void {
      activePosition.value = { ...position }

      isRowInfoDialogOpen.value = true
    }

    function onCloseAddEditDeleteInfoDialog() {
      isAddEditPositionDialogOpen.value = false

      isAddEditTransactionDialogOpen.value = false

      activePosition.value = null

      transactionToEdit.value = null

      isRowInfoDialogOpen.value = false
    }

    function onCreateTransaction(): void {
      activePosition.value = cloneDeep(props.position)

      isAddEditTransactionDialogOpen.value = true
    }

    function onPositionDelete(): void {
      activePosition.value = cloneDeep(props.position)

      if (!props.position?.sourceTransaction) {
        isDeleteDialogOpen.value = true
      } else {
        transactionToEdit.value = props.position.sourceTransaction
        isAddEditTransactionDialogOpen.value = true
      }
    }

    async function onDeletePosition(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deletePositionXhr(props.position!.id)

        addNotification({
          text: root.$t('planning.workforcePlanning.delete.message') as string,
          type: 'success',
        })

        isDeleteDialogOpen.value = false

        emit('invoke:init')
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function hasMultiplePersonsPlanned(personName: string | null): boolean {
      if (!personName) return false

      return personName.includes(',')
    }

    function convertStringToArray(personName: string): string[] {
      return personName.split(', ')
    }

    function onClickEdit(position: PositionOutput): void {
      activePosition.value = cloneDeep(position)
      isAddEditPositionDialogOpen.value = true
    }

    function getAssignmentsDetails(position: PositionOutput): AssignmentOutput[] {
      return assignments.value.filter((assignment) =>
        position.assignments.some((positionAssignment) => positionAssignment.id === assignment.id)
      )
    }

    function onCloseDeleteDialog(): void {
      activePosition.value = null
      isDeleteDialogOpen.value = false
    }

    return reactive({
      icons: {
        mdiPlus,
        mdiPencil,
        mdiDelete,
        mdiArrowLeftThin,
      },
      constants: {
        Rights,
        TABLE_HEADERS,
      },

      state: {
        activePosition,

        transactionToEdit,
        isTransactionPossible,

        isAddEditPositionDialogOpen,
        isAddEditTransactionDialogOpen,
        isDeleteDialogOpen,
        isRowInfoDialogOpen,

        isLoadingAssignments,
      },

      functions: {
        convertToEuro,
        convertSourcingType,
        hasSufficientRights,
        convertDotIntoCommaInput,

        hasMultiplePersonsPlanned,
        convertStringToArray,
        convertPositionStatus,

        getAssignmentsDetails,
      },
      listeners: {
        onPositionDelete,
        onDeletePosition,
        onCloseDeleteDialog,

        onCreateTransaction,
        onCloseAddEditDeleteInfoDialog,

        onClickEdit,

        onClickInfo,
      },
    })
  },
})
