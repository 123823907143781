import { camelCase as toCamelCase } from 'lodash-es'

import { SOURCINGTYPE } from '@/views/planning/views/workforcePlanning/types'
import { TranslateResult } from 'vue-i18n'
import { i18n } from '@/plugins/i18n'

function convertSourcingType(type: string): TranslateResult | undefined {
  switch (type) {
    case SOURCINGTYPE.INTERNAL:
      return i18n.t(`planning.workforcePlanning.form.sourcing.type.${toCamelCase(SOURCINGTYPE.INTERNAL)}`)

    case SOURCINGTYPE.EXTERNAL:
      return i18n.t(`planning.workforcePlanning.form.sourcing.type.${toCamelCase(SOURCINGTYPE.EXTERNAL)}`)

    default:
      return
  }
}

export { convertSourcingType }
