import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  TenderingOutput,
  TenderingId,
  TenderingInput,
  UseGetTenderings,
  UseGetTenderingsBasic,
  UseCreateTendering,
  UseUpdateTendering,
  UseDeleteTendering,
  UseExportTenderings,
  UseGetTendering,
} from './types/tendering'
import { OptionalFiltersParam } from '@/composables/types/useFilter'
import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'

const VERSION = 'v1'
const RESOURCE = 'tenderings'

function useGetTenderings(): UseGetTenderings {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetTenderings['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetTenderingsBasic(): UseGetTenderingsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useGetTendering(): UseGetTendering {
  const axios = useAxios<PromiseType<ReturnType<UseGetTendering['getTendering']>>>({ method: 'GET' })

  function getTendering(id: TenderingId): Promise<TenderingOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getTendering,
  }
}

function useCreateTendering(): UseCreateTendering {
  const axios = useAxios<PromiseType<ReturnType<UseCreateTendering['createTendering']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createTendering(data: TenderingInput): Promise<TenderingOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createTendering,
  }
}

function useUpdateTendering(): UseUpdateTendering {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateTendering['updateTendering']>>>({ method: 'PUT' })

  function updateTendering(id: TenderingId, data: TenderingOutput): Promise<TenderingOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateTendering,
  }
}

function useDeleteTendering(): UseDeleteTendering {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteTendering['deleteTendering']>>>({ method: 'DELETE' })

  function deleteTendering(id: TenderingId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteTendering,
  }
}

function useExportTenderings(): UseExportTenderings {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportTenderings(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportTenderings,
  }
}

export {
  useGetTenderings,
  useGetTenderingsBasic,
  useGetTendering,
  useCreateTendering,
  useUpdateTendering,
  useDeleteTendering,
  useExportTenderings,
}
